import { apiShareFile } from '@/api/api.js'
export default {
    components: {
    },
    data() {
        return {
            resultData: [],
            page: 1,
            pageNum: 5,
            loading: false,
            noMore: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getNotifyList()
        },
        getNotifyList() {
            if (this.noMore == false) {
                this.loading = true
                let paramsBase = {
                    lang: this.$i18n.locale,
                    page: this.page,
                    pageNum: this.pageNum
                }
                apiShareFile(paramsBase).then((res) => {
                    let dataValue = res.data.data
                    if (dataValue.length != 0) {
                        setTimeout(() => {
                            dataValue.forEach(element => {
                                this.resultData.push(element)
                            });
                        }, 500);

                    } else {
                        this.loading = false
                        this.noMore = true
                    }
                })
            }
        },
        load() {
            this.page++
            this.getNotifyList()
        },
        clickDownload(item) {
            window.open(item.filepath);
        }
    },
}